/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.icon-small {
    min-width: 18px !important;
    width: 18px !important;
}

.icon-medium {
    width: 28px !important;
}

.icon-big {
    min-width: 38px !important;
    width: 38px !important;
    height: 38px !important;
}

.icon-big-xl {
    min-width: 38px !important;
    width: 94px !important;
    height: 94px !important;
}

.border-bottom:hover {
    border-bottom: 2px solid;
    border-color: var(--fuse-primary);
    color: var(--fuse-primary);
}

.boder-active {
    border-bottom: 2px solid;
    border-color: var(--fuse-primary);
    color: var(--fuse-primary);
}

.custom {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-color: rgb(203 213 225 / 1) !important;
}

.myPanelClass{
    margin-top: 30px !important; 
}

.mat-menu-submenu-icon {
    display: none !important;
}